import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "snapshot"
    }}>{`Snapshot`}</h1>
    <p>{`This section describes how to retrieve snapshot metadata necessary to perform it's restore.`}</p>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.
`}<img alt="Resources view" src={require("../../assets/usage/resources-menu.png")} /></li>
      <li parentName="ol">{`Click on "three dots" on the right side of the resource for which you want to open information about the latest snapshot and select "Latest Snapshot" option.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-snapshot-menu.png")} /></li>
      <li parentName="ol">{`In the window that will appear you can see the latest snapshot and data needed to restore.
`}<img alt="Resources view" src={require("../../assets/usage/resources-latest-snapshot.png")} /></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      